<template>
    <div class="flex flex-col min-h-screen bg-slate-200/65">
        <header class="flex flex-wrap gap-4 items-center py-6 bg-gradient-to-tr from-cyan-800 to-cyan-700">
            <div class="container mx-auto flex gap-4 items-center">
                <NuxtLink to="/" class="text-white text-2xl font-bold">Logo</NuxtLink>

                <ul class="flex items-center gap-8 text-white ml-auto">
                    <li>
                        <NuxtLink class="hover:underline" to="/">Startseite</NuxtLink>
                    </li>
                    <li>
                        <NuxtLink class="hover:underline" to="/autohaus">Autohaus</NuxtLink>
                    </li>
                    <template v-if="!account">
                        <li>
                            <NuxtLink class="hover:underline" to="/mitglied-werden">Mitglied werden</NuxtLink>
                        </li>
                        <li>
                            <NuxtLink class="hover:underline" to="/anmelden">Anmelden</NuxtLink>
                        </li>
                    </template>

                    <template v-if="account">
                        <li>
                            <NuxtLink class="hover:underline" to="/abrufschein">Abrufschein</NuxtLink>
                        </li>
                        <li v-if="account.admin">
                            <NuxtLink class="hover:underline" to="/backoffice/dashboard">Backoffice</NuxtLink>
                        </li>
                        <li>
                            <MenuAccount :account="account" />
                        </li>
                    </template>
                </ul>
            </div>
        </header>

        <main class="py-12">
            <slot></slot>
        </main>

        <footer class="flex flex-wrap gap-4 items-center py-10 bg-cyan-800 mt-auto">
            <div class="container mx-auto flex gap-4 items-center text-white justify-end">
                <NuxtLink class="hover:underline" to="/kontakt">Kontakt</NuxtLink>
                <NuxtLink class="hover:underline" to="/datenschutz">Datenschutz</NuxtLink>
                <NuxtLink class="hover:underline" to="/impressum">Impressum</NuxtLink>
            </div>
        </footer>
    </div>
</template>

<script setup>
const { account } = useStore();
</script>
